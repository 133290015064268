<template>
    <article
        class="apartment-card"
        v-bind="$attrs"
        :style="{
            '--background-color': backgroundColor,
            '--text-color': textColor
        }"
    >
        <div class="apartment-card__content">
            <div
                v-if="!!$slots.type || pending"
                class="apartment-card__type"
            >
                <template v-if="pending">
                    <Skeletor
                        :shimmer="false"
                        width="40%"
                    />
                </template>
                <template v-else>
                    <slot name="type" />
                </template>
            </div>

            <div
                v-if="!!$slots.description || pending"
                class="apartment-card__description"
            >
                <template v-if="pending">
                    <h3>
                        <Skeletor
                            :shimmer="false"
                            width="80%"
                        />
                        <Skeletor
                            :shimmer="false"
                        />
                        <Skeletor
                            :shimmer="false"
                            width="35%"
                        />
                    </h3>
                </template>
                <template v-else>
                    <slot name="description" />
                </template>
            </div>

            <div
                v-if="!!$slots.facilities || pending"
                class="apartment-card__facilities"
            >
                <slot name="facilities" />
            </div>

            <div class="apartment-card__footer">
                <template v-if="pending">
                    <Skeletor
                        width="50%"
                        :shimmer="false"
                    />
                </template>
                <template v-else>
                    <BaseButton
                        v-if="link"
                        :element="NuxtLink"
                        class="base-button base-button-large"
                        :to="link.url"
                    >
                        {{ link.text }}
                    </BaseButton>
                    <BaseButton
                        v-else-if="bookable"
                        :element="NuxtLink"
                        class="base-button base-button-large"
                        @click="$emit('open-booking-engine')"
                    >
                        <slot name="cta" />
                    </BaseButton>

                    <BaseArrowLink
                        v-if="property.uri"
                        :to="property.uri"
                        label-position="right"
                    >
                        <slot name="link" />
                    </BaseArrowLink>
                </template>
            </div>
        </div>

        <BaseImage
            v-if="sticker"
            class="apartment-card__sticker"
            :image="{ url: sticker }"
        />

        <BaseSlider
            v-if="images?.length || pending"
            class="apartment-card__slider"
            :items="images"
            :controls-enabled="enableControls"
            :padding="'image-width'"
            @mouseenter="enableControls = true"
        >
            <template
                v-for="(asset, index) in images"
                #[`slide-${index}`]
                :key="`slide-${index}`"
            >
                <Skeletor
                    v-if="pending"
                    width="100%"
                    height="100%"
                    :shimmer="false"
                />
                <BaseImage
                    v-else
                    class="apartment-card__image"
                    :image="asset"
                    :width="asset.width"
                    :height="asset.height"
                    is-dragging="false"
                    sizes="(min-width: 992px) 50vw, (min-width: 642px) 600px, 93.5vw"
                />
            </template>
        </BaseSlider>
    </article>
</template>

<script setup lang="ts">
import BaseArrowLink from '~/components/BaseArrowLink.vue';
import BaseButton from '~/components/BaseButton.vue';
import BaseImage from '~/components/BaseImage.vue';
import BaseSlider from '~/components/BaseSlider.vue';

import type { ColorPalette } from '~/composables/useColorPalette';
import { useColorPalette } from '~/composables/useColorPalette';

interface Props {
    backgroundColor?: keyof ColorPalette;
    bookable?: boolean;
    images?: {[key: string]: String | Number}[];
    link?: {
        text: string;
        to: string;
        url: string;
    }| null;
    pending?: boolean;
    property: {
        bookingEngineDomain: string;
        bookingEngineId: string;
        uri: string;
    };
    sticker?: string;
}

const enableControls = ref(false);

defineEmits(['open-booking-engine']);

const props = withDefaults(defineProps<Props>(), {
    property: () => {
        return {
            bookingEngineDomain: '',
            bookingEngineId: '',
            uri: ''
        };
    },
    link: null,
    backgroundColor: () => 'pink',
    images: () => [],
    pending: () => false,
    bookable: () => false,
    sticker: () => ''
});

defineOptions({
    name: 'ApartmentCard',
});

const NuxtLink = resolveComponent('NuxtLink');

const { resolveColor, getTextColor } = useColorPalette();

const backgroundColor = computed(() => resolveColor(props.backgroundColor));
const textColor = computed(() => getTextColor(props.backgroundColor));
</script>

<style lang="less" src="./ApartmentCard.less" />
